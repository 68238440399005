@font-face {
  font-family: SpaceGroteskRegular;
  font-weight: normal;
  src: url('./fonts//SpaceGrotesk-Regular.ttf');
}

@font-face {
  font-family: SpaceGroteskBold;
  font-weight: bold;
  src: url('./fonts//SpaceGrotesk-Bold.ttf');
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
html,
body {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  font-size: 100%;
  overflow-y: scroll;
}

body {
  font-family: SpaceGroteskRegular;
}

h1,
h2,
a,
b {
  font-family: SpaceGroteskRegular;
}

h3 {
  font-family: SpaceGroteskBold;
}
